/**
 * Created by andrey on 07.02.2022.
 */

FogsConfig.initialize = cleverapps.extendFunc(FogsConfig.initialize, function () {
    this._super();

    FogsConfig.main = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        }, // wood_start
        fog1: {
            id: 1,
            price: 0,
            exp: 0,
            headless: true
        },
        fog2: {
            id: 2,
            price: 5,
            exp: 10,
            autoOpen: true,
            conditions: {
                unit: { code: "wands", stage: 0, hidden: true }
            }
        },
        fog3: {
            id: 3,
            price: 5,
            exp: 10,
            parentFogId: "fog2",
            silentUnblock: true,
            conditions: {
                level: 3
            }
        },
        fog4_0: {
            id: 4,
            price: 5,
            exp: 20,
            parentFogId: "fog3",
            conditions: {
                level: 4
            }
        },
        fog4_1: {
            id: 5,
            price: 5,
            exp: 25,
            parentFogId: "fog3",
            conditions: {
                level: 4
            }
        }, // wood_castle
        fog5: {
            id: 6,
            price: 15,
            exp: 50,
            parentFogId: "fog4_0",
            conditions: {
                level: 5
            }
        },
        fog6: {
            id: 7,
            price: 20,
            exp: 50,
            parentFogId: "fog4_1",
            conditions: {
                level: 6,
                hero: "king"
            }
        }, // wood_generator
        fog7: {
            id: 8,
            price: 115,
            exp: 50,
            parentFogId: "fog6",
            conditions: {
                level: 7
            }
        }, // stone_start
        fog8: {
            id: 9,
            price: 225,
            exp: 50,
            parentFogId: "fog7",
            conditions: {
                level: 8,
                hero: "bear"
            }
        }, // stone_castle
        fog9: {
            id: 10,
            price: 500,
            exp: 50,
            parentFogId: "fog7",
            conditions: {
                level: 9,
                hero: "bear"
            }
        },
        fog10: {
            id: 11,
            price: 1000,
            exp: 50,
            parentFogId: "fog9",
            conditions: {
                level: 10
            }
        }, // stone_generator + wagon_start

        fog11: {
            id: 12,
            price: 1500,
            exp: 50,
            parentFogId: "fog8",
            conditions: {
                level: 11
            }
        }, // wagon_castle
        fog12: {
            id: 13,
            price: 1300,
            exp: 50,
            parentFogId: "fog11",
            conditions: {
                level: 12,
                hero: "elf"
            }
        }, // wagon_generator -elf

        fog13: {
            id: 14,
            price: 1600,
            exp: 50,
            parentFogId: "fog12",
            conditions: {
                level: 13,
                family: "wood"
            }
        }, // bamboo_start
        fog14: {
            id: 15,
            price: 1950,
            exp: 50,
            parentFogId: "fog13",
            conditions: {
                level: 14,
                family: "wood"
            }
        }, // bamboo_castle
        fog15: {
            id: 16,
            price: 2350,
            exp: 50,
            parentFogId: "fog13",
            conditions: {
                level: 15,
                family: "wood",
                hero: "warlock"
            }
        }, // bamboo_generator -warlock

        fog16: {
            id: 17,
            price: 2800,
            exp: 50,
            parentFogId: "fog15",
            conditions: {
                level: 16,
                family: "stone"
            }
        }, // sapphire_start
        fog17: {
            id: 18,
            price: 3300,
            exp: 50,
            parentFogId: "fog16",
            conditions: {
                level: 17,
                family: "stone"
            }
        }, // sapphire_castle
        fog18: {
            id: 19,
            price: 4160,
            exp: 50,
            parentFogId: "fog17",
            conditions: {
                level: 18,
                family: "stone",
                hero: "troll"
            }
        }, // sapphire_generator -troll

        fog19: {
            id: 20,
            price: 5200,
            exp: 50,
            parentFogId: "fog18",
            conditions: {
                level: 19,
                hero: "troll",
                family: "wagon"
            }
        }, // snow_start
        fog20: {
            id: 21,
            price: 7150,
            exp: 50,
            parentFogId: "fog19",
            conditions: {
                level: 20,
                family: "wagon"
            }
        }, // snow_castle
        fog21: {
            id: 22,
            price: 8400,
            exp: 50,
            parentFogId: "fog20",
            conditions: {
                level: 21,
                family: "wagon"
            }
        }, // snow_generator
        fog22: {
            id: 30,
            price: 9600,
            exp: 50,
            parentFogId: "fog21",
            conditions: {
                family: "bamboo"
            }
        }, // lava_start
        fog23: {
            id: 31,
            price: 10900,
            exp: 50,
            parentFogId: "fog22",
            conditions: {
                family: "bamboo"
            }
        }, // lava_castle
        fog24: {
            id: 32,
            price: 12400,
            exp: 50,
            parentFogId: "fog23",
            conditions: {
                family: "bamboo"
            }
        }, // lava_generator

        fog25: {
            id: 37,
            price: 9600,
            exp: 50,
            parentFogId: "fog24",
            conditions: {
                family: "sapphire"
            }
        }, // sand_start
        fog26: {
            id: 38,
            price: 10900,
            exp: 50,
            parentFogId: "fog25",
            conditions: {
                family: "sapphire"
            }
        }, // sand_castle
        fog27: {
            id: 39,
            price: 12400,
            exp: 50,
            parentFogId: "fog26",
            conditions: {
                family: "sapphire"
            }
        }, // sand_generator

        fog28: {
            id: 41,
            price: 9600,
            exp: 50,
            parentFogId: "fog27",
            conditions: {
                family: "snow"
            }
        }, // asia_start
        fog29: {
            id: 42,
            price: 10900,
            exp: 50,
            parentFogId: "fog28",
            conditions: {
                family: "snow"
            }
        }, // asia_castle
        fog30: {
            id: 43,
            price: 12400,
            exp: 50,
            parentFogId: "fog29",
            conditions: {
                family: "snow",
                hero: "gnomes"
            }
        }, // asia_generator -gnomes
        fog31: {
            id: 46,
            price: 13640,
            exp: 50,
            parentFogId: "fog30",
            conditions: {
                family: "lava",
                hero: "shark"
            }
        }, // warcraft_start
        fog32: {
            id: 47,
            price: 15000,
            exp: 50,
            parentFogId: "fog31",
            conditions: {
                family: "lava"
            }
        }, // warcraft_castle
        fog33: {
            id: 48,
            price: 16400,
            exp: 50,
            parentFogId: "fog32",
            conditions: {
                family: "lava"
            }
        }, // warcraft_generator -shark

        fog34: {
            id: 50,
            price: 18040,
            exp: 50,
            parentFogId: "fog33",
            conditions: {
                family: "sand",
                hero: "fish"
            }
        }, // steampunk_start
        fog35: {
            id: 51,
            price: 19800,
            exp: 50,
            parentFogId: "fog34",
            conditions: {
                family: "sand"
            }
        }, // steampunk_castle
        fog36: {
            id: 52,
            price: 22700,
            exp: 50,
            parentFogId: "fog35",
            conditions: {
                family: "sand"
            }
        }, // steampunk_generator -fish

        fog37: {
            id: 53,
            price: 24000,
            exp: 50,
            parentFogId: "fog36",
            conditions: {
                family: "asia",
                hero: "panda"
            }
        }, // moonstone_start
        fog38: {
            id: 54,
            price: 25500,
            exp: 50,
            parentFogId: "fog37",
            conditions: {
                family: "asia"
            }
        }, // moonstone_castle
        fog39: {
            id: 55,
            price: 28000,
            exp: 50,
            parentFogId: "fog38",
            conditions: {
                family: "asia"
            }
        }, // moonstone_generator -panda

        moneyFog1: {
            id: 23,
            price: 240,
            windowSkin: "green",
            money: true,
            parentFogId: "fog5"
        },
        moneyFog2: {
            id: 24,
            price: 699,
            windowSkin: "green",
            noTerrainInBuyWindow: true,
            money: true,
            parentFogId: "fog6",
            conditions: {
                hero: "king"
            }
        },
        moneyFog3: {
            id: 25,
            price: 899,
            windowSkin: "green",
            noTerrainInBuyWindow: true,
            money: true,
            parentFogId: "fog7",
            conditions: {
                hero: "king"
            }
        },
        moneyFog4: {
            id: 26,
            price: 799,
            windowSkin: "green",
            noTerrainInBuyWindow: true,
            money: true,
            parentFogId: "fog8",
            conditions: {
                hero: "bear"
            }
        },
        moneyFog5: {
            id: 27,
            price: 1799,
            windowSkin: "green",
            noTerrainInBuyWindow: true,
            money: true,
            parentFogId: "fog9"
        },
        moneyFog6: {
            id: 33,
            price: 1499,
            windowSkin: "green",
            money: true,
            parentFogId: "fog10",
            conditions: {
                hero: "bear"
            }
        },
        moneyFog7: {
            id: 34,
            price: 3599,
            money: true,
            windowSkin: "green",
            soft: true,
            parentFogId: "fog10"
        },
        moneyFog8: {
            id: 35,
            price: 1100,
            windowSkin: "green",
            money: true,
            parentFogId: "fog14",
            conditions: {
                hero: "warlock"
            }
        },
        moneyFog9: {
            id: 36,
            price: 1199,
            windowSkin: "green",
            money: true,
            parentFogId: "fog13"
        },
        moneyFog10: {
            id: 40,
            price: 3499,
            windowSkin: "green",
            money: true,
            parentFogId: "fog15"
        },
        moneyFog11: {
            id: 44,
            price: 1999,
            windowSkin: "green",
            money: true,
            parentFogId: "fog15"
        },
        moneyFog12: {
            id: 56,
            price: 2499,
            windowSkin: "green",
            money: true,
            parentFogId: "fog17"
        },
        moneyFog13: {
            id: 57,
            price: 1999,
            windowSkin: "green",
            money: true,
            parentFogId: "fog21"
        },
        moneyFog14: {
            id: 58,
            price: 1999,
            windowSkin: "green",
            money: true,
            parentFogId: "fog24"
        },
        moneyFog15: {
            id: 59,
            price: 1999,
            windowSkin: "green",
            money: true,
            parentFogId: "fog30"
        },
        moneyFog16: {
            id: 60,
            price: 999,
            windowSkin: "green",
            money: true,
            parentFogId: "fog36"
        },

        fog99: {
            id: 28,
            price: 1000,
            exp: 50,
            available: false,
            conditions: {
                level: 100
            }
        },

        dragonIsland: {
            id: 29,
            special: true,
            price: 1000,
            parentFogId: "fog5"
        },

        landmarkfog: {
            id: 45,
            price: 0,
            exp: 0,
            parentFogId: "fog6"
        }
    };

    FogsConfig.afterInitialize();
});

FogsConfig.initialize();
